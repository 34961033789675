<script lang="ts" setup>
import type { EditorialPage } from '@integration-layer/ampliance/schemas/editorial-page-schema.localized'

const props = defineProps<{
  deliveryId: string
  locale: string
  vse?: string
  content?: EditorialPage
}>()

const data = await useComponentResponse<EditorialPage>(props)

const components = computed(() =>
  connectComponents(
    // @ts-ignore
    data.value?.logicWrapper ?? [],
    props.locale,
    props.vse
  )
)

const seo = data.value?.seo
const seoExtraData: EditorialMeta = {
  type: 'editorial',
  title: data.value?._meta.name ?? '',
  // @ts-ignore
  brand: data.value?.parentPage?.parentPage?.categoryTitle,
}
if (seo)
  await useAmplienceSeo({
    // @ts-ignore
    deliveryId: seo._meta.deliveryId,
    content: seo,
    extraData: seoExtraData,
  })

const transformedData = mappedParentPage({
  ...data.value,
  categoryTitle: data.value?._meta.name ?? '',
})

provide('plpshopthelook', transformedData)
</script>

<template>
  <div>
    <AmplienceDynamicContent :components></AmplienceDynamicContent>
    <MoleculesBackToTop />
  </div>
</template>
