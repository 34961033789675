export const hasParentPageKey = (
  obj: unknown
): obj is { parentPage: unknown } => {
  return typeof obj === 'object' && obj !== null && 'parentPage' in obj
}

export const mappedParentPage = (obj: any) => {
  if (!hasParentPageKey(obj)) obj

  const mappedObj: {
    categoryTitle?: string
    subcategory?: boolean
    urlSlug?: string
    categoryId?: string
    brand?: string
    showMegalogo?: boolean
    parentPage?: any
  } = {
    categoryTitle: obj.categoryTitle || '',
    subcategory: obj.subcategory || false,
    urlSlug: obj.urlSlug || '',
    categoryId: obj.categoryId || '',
    brand: obj.brand || '',
    showMegalogo: obj.showMegalogo || false,
  }

  if (hasParentPageKey(obj)) {
    mappedObj.parentPage = mappedParentPage(obj.parentPage)
  }

  return mappedObj
}
